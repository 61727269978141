<template>
  <Dialog :commonDialog.sync="serviceFormDialog">
    <template v-slot:title>
      Service Form
      <v-icon small class="mx-4 color-custom-blue"
        >mdi-checkbox-blank-circle</v-icon
      >
      Job# {{ detail.barcode }}
    </template>
    <template v-slot:body>
      <!-- <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll custom-box-top-inner-shadow"
        style="max-height: 60vh; position: relative"
      > -->
      <v-form
        ref="serviceForm"
        v-model.trim="formValid"
        lazy-validation
        v-on:submit.stop.prevent="updateOrCreate"
      >
        <v-container>
          <v-row>
            <v-col md="12">
              <v-autocomplete
                v-model.trim="data.service_form"
                :items="serviceFormList"
                hide-details
                dense
                filled
                label="Service Form"
                item-color="cyan"
                color="cyan"
                solo
                flat
                :disabled="pageLoading"
                item-text="label"
                item-value="value"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title
                      v-html="'Nothing Found.'"
                    ></v-list-item-title>
                  </v-list-item>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col md="12" v-if="!isVisitDetail">
              <v-autocomplete
                v-model.trim="data.visit"
                :items="visitList"
                hide-details
                dense
                filled
                label="Visit"
                item-color="cyan"
                color="cyan"
                solo
                flat
                :disabled="pageLoading"
                item-text="text"
                item-value="id"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title
                      v-html="'Nothing Found.'"
                    ></v-list-item-title>
                  </v-list-item>
                </template>
                <template v-slot:selection="{ item }">
                  <p class="m-0 font-size-16 text-ellipsis text-capitalize">
                    {{ item.barcode }} ({{ formatDateTime(item.started_at) }} -
                    {{ formatTime(item.finished_at) }})
                  </p>
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item-content :class="[item.header_text]">
                    <v-list-item-title
                      class="font-size-16 pl-2 text-ellipsis pb-1 text-capitalize"
                      :style="{ color: item.header_color }"
                      >{{ item.barcode }} ({{
                        formatDateTime(item.started_at)
                      }}
                      - {{ formatTime(item.finished_at) }})</v-list-item-title
                    >
                    <v-list-item-subtitle
                      class="font-size-14 pl-2 text-ellipsis pb-1"
                      :style="{ color: item.header_color }"
                      >{{
                        engineerDisplayText(item.engineers)
                      }}</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
      <!-- </perfect-scrollbar> -->
    </template>
    <template v-slot:action>
      <v-btn
        :disabled="!formValid || formLoading || pageLoading"
        :loading="formLoading"
        class="mx-2 custom-grey-border custom-bold-button white--text"
        color="cyan"
        v-on:click="updateOrCreate"
      >
        Save
      </v-btn>
      <v-btn
        :disabled="formLoading || pageLoading"
        class="mx-2 custom-grey-border custom-bold-button"
        v-on:click="$emit('close-dialog', true)"
      >
        Close
      </v-btn>
    </template>
  </Dialog>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import { GET, POST } from "@/core/services/store/request.module";
import Dialog from "@/view/pages/partials/Dialog.vue";
import { DialogJobEventBus } from "@/core/lib/job/dialog.job.lib";

export default {
  mixins: [CommonMixin],
  props: {
    serviceFormDialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    detail: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    visit: {
      type: Object,
      default() {
        return new Object();
      },
    },
    isVisitDetail: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    visit: {
      deep: true,
      immediate: true,
      handler(param) {
        this.data.visit = this.lodash.toSafeInteger(param.id);
      },
    },
    detail: {
      deep: true,
      immediate: true,
      handler(param) {
        this.data.ticket = this.lodash.toSafeInteger(param.id);
      },
    },
  },
  data() {
    return {
      pageLoading: false,
      formValid: true,
      formLoading: false,
      visitList: new Array(),
      serviceFormList: [
        {
          label: "Service Support and Incident Form",
          value: "service-form-b1",
        },
        { label: "Maintenance Form", value: "service-form-b2" },
        { label: "Warranty Form", value: "service-form-b3" },
      ],
      data: {
        service_form: null,
        visit: 0,
        ticket: 0,
      },
    };
  },
  methods: {
    updateOrCreate() {
      const _this = this;

      if (!_this.$refs.serviceForm.validate()) {
        return false;
      }

      _this.formLoading = true;

      _this.$store
        .dispatch(POST, {
          url:
            "job/" +
            _this.detail.id +
            "/visit/" +
            _this.data.visit +
            "/service-form/" +
            _this.data.service_form,
        })
        .then(() => {
          _this.$emit("create:service-form", true);
          DialogJobEventBus.$emit("load:job", true);
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
    getJobVisits() {
      const _this = this;
      _this.$store
        .dispatch(GET, {
          url: "job/" + _this.detail.id + "/visit",
        })
        .then(({ data }) => {
          if (_this.lodash.isArray(data)) {
            _this.visitList = data.map((row) => {
              if (row.header) {
                return { header: row.header_text };
              }
              return row;
            });
          }
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    engineerDisplayText(param) {
      const _this = this;
      let display_name = new Array();
      if (
        _this.lodash.isEmpty(param) === false &&
        _this.lodash.isArray(param)
      ) {
        for (let i = 0; i < param.length; i++) {
          if (_this.lodash.isEmpty(param[i].engineer) === false) {
            display_name.push(param[i].engineer.display_name);
          }
        }
      }
      if (_this.lodash.isEmpty(display_name)) {
        return "Not assigned yet.";
      } else {
        return "Assigned to " + display_name.join(", ");
      }
    },
  },
  components: {
    Dialog,
  },
  mounted() {
    const _this = this;
    _this.data.ticket = _this.detail.id;
    _this.getJobVisits();
  },
};
</script>
