<template>
  <div
    v-if="getPermission('visit:view') && lodash.isEmpty(detail) === false"
    class="visit-detail"
  >
    <v-container fluid class="custom-bthrust-style">
      <v-container fluid class="pl-0 pr-0">
        <v-layout class="justify-end">
          <div class="mr-2">
            <v-text-field
              v-model.trim="filter.search"
              v-on:click:clear="clearFilter('search')"
              clearable
              dense
              filled
              flat
              label="Search"
              hide-details
              solo
              :disabled="visitLoading"
              color="cyan"
              @keydown.enter="getVisitList"
              @keydown.tab="getVisitList"
            ></v-text-field>
          </div>
          <div class="mx-2">
            <v-autocomplete
              v-model.trim="filter.engineer"
              hide-details
              :items="engineerList"
              clearable
              dense
              flat
              filled
              label="Engineer"
              item-color="cyan"
              color="cyan"
              solo
              :disabled="visitLoading"
              item-text="display_name"
              item-value="id"
              v-on:change="getVisitList"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title
                    v-html="'No Engineer(s) Found.'"
                  ></v-list-item-title>
                </v-list-item>
              </template>
              <template v-slot:item="{ item }">
                <v-list-item-avatar>
                  <v-img
                    :lazy-src="$defaultProfileImage"
                    :src="$assetAPIURL(item.profile_logo)"
                    aspect-ratio="1"
                    class="margin-auto grey lighten-5 custom-grey-border"
                    transition="fade-transition"
                  ></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title
                    class="text-capitalize font-weight-500 font-size-16"
                    >#{{ item.barcode }}</v-list-item-title
                  >
                  <v-list-item-title
                    class="text-capitalize font-weight-500 font-size-16"
                    >{{ item.display_name }}</v-list-item-title
                  >
                </v-list-item-content>
                <v-list-item-action class="align-self-center">
                  <v-list-item-subtitle
                    class="text-lowercase pb-2 font-weight-500 font-size-14"
                    >{{ item.primary_email }}</v-list-item-subtitle
                  >
                  <v-list-item-subtitle
                    class="text-lowercase font-weight-500 font-size-14"
                    >{{ item.primary_phone }}</v-list-item-subtitle
                  >
                </v-list-item-action>
              </template>
            </v-autocomplete>
          </div>
          <div class="mx-2">
            <v-autocomplete
              v-model.trim="filter.type"
              hide-details
              :items="typeList"
              clearable
              dense
              flat
              filled
              label="Type"
              item-color="cyan"
              color="cyan"
              solo
              :disabled="visitLoading"
              item-text="label"
              item-value="value"
              v-on:change="getVisitList"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title
                    v-html="'No Type(s) Found.'"
                  ></v-list-item-title>
                </v-list-item>
              </template>
            </v-autocomplete>
          </div>
          <div class="mx-2">
            <v-autocomplete
              v-model.trim="filter.status"
              hide-details
              :items="statusList"
              clearable
              dense
              flat
              filled
              label="Status"
              item-color="cyan"
              color="cyan"
              solo
              :disabled="visitLoading"
              item-text="text"
              item-value="status"
              v-on:change="getVisitList"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title
                    v-html="'No Status(s) Found.'"
                  ></v-list-item-title>
                </v-list-item>
              </template>
            </v-autocomplete>
          </div>
          <div class="mx-2">
            <v-menu
              v-model="datePicker"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  filled
                  flat
                  label="Visit Date Range"
                  hide-details
                  solo
                  :disabled="visitLoading"
                  v-on:click:clear="clearFilter('dates')"
                  clearable
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :value="formattedDate"
                  color="cyan"
                ></v-text-field>
              </template>
              <v-date-picker
                color="cyan"
                range
                :disabled="visitLoading"
                v-on:change="getVisitList"
                v-model="dates"
              ></v-date-picker>
            </v-menu>
          </div>
          <div class="ml-2 d-flex">
            <!--  buttons -->
            <v-tooltip top content-class="custom-top-tooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  class="mr-2 custom-bold-button white--text"
                  color="cyan"
                  tile
                  :disabled="visitLoading"
                  v-on:click="getVisitList"
                >
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
              </template>
              <span>Search</span>
            </v-tooltip>
            <v-tooltip top content-class="custom-top-tooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  class="mx-2 custom-bold-button white--text"
                  color="cyan"
                  tile
                  :disabled="visitLoading"
                  v-on:click="getVisitList"
                >
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>
              </template>
              <span>Reload</span>
            </v-tooltip>
            <template
              v-if="detail.status == 1 && getPermission('visit:create')"
            >
              <v-tooltip top content-class="custom-top-tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    class="ml-2 custom-bold-button white--text"
                    color="cyan"
                    tile
                    :disabled="visitLoading"
                    v-on:click="createVisit"
                  >
                    <v-icon>mdi-truck-fast-outline</v-icon>
                  </v-btn>
                </template>
                <span>Create Visit</span>
              </v-tooltip>
            </template>
          </div>
        </v-layout>
      </v-container>
      <v-skeleton-loader
        type="text@10"
        class="custom-skeleton table-rows-text"
        v-if="visitLoading"
      ></v-skeleton-loader>
      <v-simple-table
        v-else
        class="inner-simple-table custom-border-top"
        fixed-header
      >
        <template v-slot:default>
          <tbody>
            <template v-if="lodash.isEmpty(rowData) === false">
              <template v-for="(row, index) in rowData">
                <tr
                  v-if="row.header"
                  :key="index"
                  :class="{ 'custom-border-top': index > 0 }"
                >
                  <td
                    colspan="5"
                    class="font-size-16 text-uppercase py-2 font-weight-700 custom-border-bottom"
                    :class="[row.header_text]"
                    :style="{ color: row.header_color }"
                  >
                    {{ row.header_text }}
                  </td>
                </tr>
                <template v-else>
                  <v-hover v-slot="{ hover }" :key="index">
                    <tr
                      link
                      :key="'visit-row' + index"
                      :class="{
                        'visit-row-hover': hover,
                        'custom-border-bottom': rowData[index + 1],
                        'row-loading': isRowLoading[index],
                      }"
                      v-on:click="visitDetail(index, row.visit_id)"
                    >
                      <td
                        :class="[row.header_text]"
                        class="font-size-16 font-weight-500"
                        :style="{ color: row.header_color }"
                      >
                        <template v-if="row.visit_barcode">
                          <Barcode :barcode="row.visit_barcode"></Barcode>
                        </template>
                        <template v-else> - </template>
                      </td>
                      <td
                        :class="[row.header_text]"
                        class="font-size-16 font-weight-500"
                        :style="{ color: row.header_color }"
                      >
                        <p class="m-0 custom-nowrap-ellipsis max-width-500px">
                          {{ row.visit_title }}
                        </p>
                      </td>
                      <td
                        :class="[row.header_text]"
                        class="font-size-16 font-weight-500"
                        :style="{ color: row.header_color }"
                      >
                        <p class="m-0 max-content-width custom-nowrap-ellipsis">
                          {{ formatDateTime(row.visit_started_at) }} -
                          {{ formatTime(row.visit_finished_at) }}
                        </p>
                        <p class="m-0 max-content-width custom-nowrap-ellipsis">
                          <v-chip
                            class="mt-2 mb-2 font-weight-600 cursor-pointer text-lowercase"
                            color="chip-custom-blue"
                            outlined
                            label
                          >
                            <template v-if="row.visit_status === 4">
                              completed in {{ row.visit_duration_human }}
                            </template>
                            <template v-else-if="row.visit_status === 2">
                              in-progress
                            </template>
                            <template v-else>
                              <template v-if="row.visit_overdue === 1"
                                >delayed by
                                {{
                                  calculateTotalDuration(row.visit_started_at)
                                }}</template
                              >
                              <template v-else-if="row.visit_today === 1"
                                >ends in
                                {{
                                  calculateTotalDuration(row.visit_finished_at)
                                }}</template
                              >
                              <template v-else
                                >starts in
                                {{
                                  calculateTotalDuration(row.visit_started_at)
                                }}</template
                              >
                            </template>
                          </v-chip>
                        </p>
                      </td>
                      <td>
                        <CustomStatus
                          :status.sync="row.visit_status"
                          endpoint="visit/status"
                        ></CustomStatus>
                      </td>
                      <td class="font-size-16 font-weight-500">
                        <p
                          class="m-0 max-content-width custom-nowrap-ellipsis"
                          :class="{
                            'text--secondary': lodash.isEmpty(
                              row.visit_engineers
                            ),
                          }"
                        >
                          {{ engineerDisplayText(row.visit_engineers) }}
                        </p>
                      </td>
                    </tr>
                  </v-hover>
                </template>
              </template>
            </template>
            <template v-else>
              <tr>
                <td colspan="5">
                  <p
                    class="m-0 row-not-found text-center font-weight-500 font-size-16"
                  >
                    Sorry! No Visit(s) Found.
                  </p>
                </td>
              </tr>
            </template>
          </tbody>
        </template>
      </v-simple-table>
    </v-container>
    <template v-if="getPermission('visit:view')">
      <SingleVisitDetail
        v-if="visitId > 0"
        :detail.sync="detail"
        :visit.sync="visitId"
        :customer.sync="customer"
        :property.sync="property"
        :billing.sync="billing"
        :contactPerson.sync="contactPerson"
        :pageLoading.sync="pageLoading"
      ></SingleVisitDetail>
    </template>
    <template v-if="getPermission('visit:create')">
      <AddVisitDialog
        v-if="addVisit"
        :detail.sync="detail"
        :customer.sync="customer"
        :property.sync="property"
        :billing.sync="billing"
        :contactPerson.sync="contactPerson"
        :dailyRecurrence.sync="dailyRecurrence"
        :monthlyRecurrence.sync="monthlyRecurrence"
        :weeklyRecurrence.sync="weeklyRecurrence"
        :pageLoading.sync="pageLoading"
      ></AddVisitDialog>
    </template>
  </div>
</template>

<script>
import moment from "moment-timezone";
import CommonMixin from "@/core/plugins/common-mixin";
import AddVisitDialog from "@/view/pages/job/partials/Add-Visit-Dialog.vue";
import SingleVisitDetail from "@/view/pages/job/partials/Single-Visit-Detail.vue";
import { DialogJobEventBus } from "@/core/lib/job/dialog.job.lib";
import { GET, PATCH } from "@/core/services/store/request.module";
import JobRequestMixin from "@/core/lib/job/api.request.job.mixin";
import Barcode from "@/view/pages/partials/Barcode.vue";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import CustomStatus from "@/view/pages/partials/CustomStatus.vue";
import LocalService from "@/core/services/local.service";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin, JobRequestMixin],
  props: {
    pageLoading: {
      required: true,
      type: Boolean,
      default: false,
    },
    detail: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    customer: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    property: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    billing: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    contactPerson: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    dailyRecurrence: {
      type: Object,
      default() {
        return new Object();
      },
    },
    monthlyRecurrence: {
      type: Object,
      default() {
        return new Object();
      },
    },
    weeklyRecurrence: {
      type: Object,
      default() {
        return new Object();
      },
    },
  },
  data() {
    return {
      totalRows: 0,
      rowsOffset: 0,
      currentPage: 1,
      totalPages: 0,
      perPage: 10,
      isRowLoading: {},
      addVisit: false,
      job: 0,
      visitId: 0,
      dates: [],
      statusList: [],
      engineerList: [],
      typeList: [
        { label: "All Type", value: "all" },
        { label: "Overdue", value: "overdue" },
        { label: "Today", value: "today" },
        { label: "Tomorrow", value: "tomorrow" },
        { label: "This Week", value: "this-week" },
        { label: "Upcomming", value: "upcomming" },
        { label: "Completed", value: "completed" },
      ],
      rowData: [],
      filter: {
        search: null,
        engineer: 0,
        status: 0,
        type: "all",
      },
      visitLoading: false,
      timeout: null,
      timeoutLimit: 500,
    };
  },
  components: {
    Barcode,
    AddVisitDialog,
    SingleVisitDetail,
    CustomStatus,
  },
  methods: {
    calculateTotalDuration(visit_date) {
      if (moment(visit_date).isValid()) {
        let a = moment(visit_date);
        let b = moment();

        let totalDuration = moment.duration(a.diff(b));
        if (b.isAfter(a)) {
          totalDuration = moment.duration(b.diff(a));
        }

        let result = [];

        let years = totalDuration.get("years");
        if (years > 0) {
          if (years == 1) {
            result.push(years + " Year");
          } else {
            result.push(years + " Years");
          }
        }

        let months = totalDuration.get("months");
        if (months > 0) {
          if (months == 1) {
            result.push(months + " Month");
          } else {
            result.push(months + " Months");
          }
        }

        let days = totalDuration.get("days");
        if (days > 0) {
          if (days == 1) {
            result.push(days + " Day");
          } else {
            result.push(days + " Days");
          }
        }

        let hours = totalDuration.get("hours");
        if (hours > 0) {
          if (hours == 1) {
            result.push(hours + " Hour");
          } else {
            result.push(hours + " Hours");
          }
        }

        let minutes = totalDuration.get("minutes");
        if (minutes > 0) {
          if (minutes == 1) {
            result.push(minutes + " Minute");
          } else {
            result.push(minutes + " Minutes");
          }
        }

        return result.join(" ");
      }
    },
    createVisit() {
      DialogJobEventBus.$emit("open:add-visit-dialog", true);
    },
    markAsComplete(status, index, id) {
      const _this = this;
      _this.isRowLoading[index] = true;
      _this.$store
        .dispatch(PATCH, {
          url:
            "job/" +
            _this.detail.id +
            "/visit/" +
            id +
            "/update-status/" +
            +status,
        })
        .then(() => {
          DialogJobEventBus.$emit("load:job", true);
        })
        .catch((error) => {
          _this.rowData[index].status = !status;
          _this.logError(error);
        })
        .finally(() => {
          _this.isRowLoading = {};
        });
    },
    visitDetail(index, id) {
      this.visitId = id;
      this.isRowLoading[index] = true;
    },
    engineerDisplayText(param) {
      const _this = this;
      let display_name = new Array();
      if (
        _this.lodash.isEmpty(param) === false &&
        _this.lodash.isArray(param)
      ) {
        for (let i = 0; i < param.length; i++) {
          display_name.push(param[i].display_name);
        }
      }
      if (_this.lodash.isEmpty(display_name)) {
        return "Not assigned yet.";
      } else {
        return "Assigned to " + display_name.join(", ");
      }
    },
    clearFilter(field) {
      const _this = this;
      switch (field) {
        case "search":
          _this.filter.search = null;
          break;
        case "dates":
          _this.dates = [];
          break;
      }
      _this.getVisitList();
    },
    getVisitList() {
      const _this = this;
      let filter = {
        search: _this.filter.search,
        engineer: _this.lodash.toSafeInteger(_this.filter.engineer),
        type: _this.filter.type || "all",
        status: _this.lodash.toSafeInteger(_this.filter.status),
        daterange: _this.dates,
      };
      if (_this.lodash.isEmpty(_this.dates) === false) {
        if (moment(_this.dates[0]).isAfter(_this.dates[1])) {
          ErrorEventBus.$emit(
            "update:error",
            InitializeError("End Date must be after Start Date.")
          );
          return false;
        }
      }
      _this.visitLoading = true;
      let current_page = _this.currentPage;
      let per_page = _this.perPage;
      _this
        .getVisits(_this.job, { filter, current_page, per_page })
        .then((data) => {
          _this.rowData = data.rows;
          _this.totalPages = Math.ceil(data.total_rows / data.per_page);
          _this.currentPage = data.current_page;
          _this.totalRows = data.total_rows;
          _this.rowsOffset = data.offset;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.visitLoading = false;
        });
    },
    getEngineerList() {
      const _this = this;
      _this
        .getEngineers(_this.job)
        .then(({ rows }) => {
          if (_this.lodash.isEmpty(rows) === false) {
            _this.engineerList = _this.lodash.compact(
              rows.map((row) => {
                return row.engineer;
              })
            );
          }
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    get_status_list() {
      const _this = this;
      return new Promise((resolve, reject) => {
        let cache_response = LocalService.getData("visit/status");
        if (_this.lodash.isEmpty(cache_response) === false) {
          resolve(cache_response);
        } else {
          _this.$store
            .dispatch(GET, { url: "visit/status" })
            .then((response) => {
              resolve(response.data);
              LocalService.saveData("visit/status", response.data);
            })
            .catch((error) => {
              reject(error);
            });
        }
      });
    },
  },
  mounted() {
    this.getVisitList();
    this.getEngineerList();
    this.get_status_list()
      .then((data) => {
        this.statusList = data;
      })
      .catch((error) => {
        this.logError(error);
      });
  },
  beforeDestroy() {
    /*DialogJobEventBus.$off("close:single-visit-detail-dialog");
    DialogJobEventBus.$off("load:visit");
    DialogJobEventBus.$off("close:add-visit-dialog");
    DialogJobEventBus.$off("open:add-visit-dialog");
    DialogJobEventBus.$off("loader:visit-row");*/
  },
  created() {
    const _this = this;

    _this.job = _this.lodash.toSafeInteger(_this.$route.params.id);
    if (!_this.job || _this.job <= 0) {
      _this.$router.go(-1);
    }

    DialogJobEventBus.$on("close:single-visit-detail-dialog", function () {
      _this.visitId = 0;
    });

    DialogJobEventBus.$on("load:visit", function () {
      _this.getVisitList();
    });

    DialogJobEventBus.$on("close:add-visit-dialog", function () {
      _this.addVisit = false;
    });

    DialogJobEventBus.$on("open:add-visit-dialog", function () {
      _this.addVisit = true;
    });

    DialogJobEventBus.$on("loader:visit-row", function (argument) {
      if (!argument) {
        _this.isRowLoading = {};
      }
    });
  },
};
</script>
